<template>
   <v-container fluid>
      <v-row>
         <v-col cols="12">
            <v-card class="" elevation="" max-width="">
               <v-card-title class="justify-center"> MODIFICAR PRODUCTO </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <v-col cols="12">
                        <p>
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }">Inicio</router-link>
                           /
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'productosPanel' }">Productos</router-link>
                           /
                           <router-link
                              exact=""
                              style="text-decoration: none"
                              :to="{
                                 name: 'productosEditarPanel',
                                 params: { idproducto: $route.params.idproducto },
                              }"
                              >Editar</router-link
                           >
                        </p>
                     </v-col>
                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12 text-center" v-show="!mostrarContenido">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>
                  </v-row>

                  <v-form @submit.prevent="modificar()" v-show="mostrarContenido" :disabled="processForm">
                     <v-row>
                        <v-col cols="12">
                           <v-alert type="error" v-model="alertError" dismissible ref="mensajeError" id="mensajeError">
                              <ul v-for="(errors, index) in listErrors" :key="index">
                                 <li v-text="errors[0]"></li>
                              </ul>
                           </v-alert>
                        </v-col>

                        <v-col cols="12">
                           <v-select
                              @change="evtTipoProducto"
                              dense
                              item-text="nombre"
                              item-value="idtipo_producto"
                              :items="resources.tipo_producto"
                              label="Tipo producto (*)"
                              outlined
                              v-model="producto.idtipo_producto"
                           ></v-select>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                           <v-text-field dense label="Nombre(*)" v-model="producto.nombre"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                           <v-text-field dense label="Codigo" v-model="producto.codigo"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                           <v-text-field type="number" min="0" dense label="Precio lista" v-model="producto.precio_lista"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
                           <v-text-field type="number" min="0" dense label="Precio" v-model="producto.precio"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select dense item-text="nombre" item-value="idmodelo_producto" :items="resources.clase" multiple label="Clase" outlined v-model="producto.idclase"></v-select>
                        </v-col>

                        <!-- <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select
                           @change="getRangos()"
                           dense
                           item-text="nombre"
                           item-value="idcategoria"
                           :items="resources.categoria"
                           label="Categoria"
                           outlined
                           v-model="producto.idcategoria"
                           ></v-select>
                        </v-col> -->

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select
                              @change="getRangos()"
                              dense
                              item-text="nombre"
                              item-value="idgenero"
                              :items="resources.genero"
                              label="Generos"
                              outlined
                              multiple
                              v-model="producto.idgenero"
                           ></v-select>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select dense item-text="nombre" item-value="idmarco" :items="resources.marco" label="Marco" outlined v-model="producto.idmarco"></v-select>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select
                              v-show="resources.rango.length > 0"
                              dense
                              item-text="nombre"
                              item-value="idrango_edad"
                              :items="resources.rango"
                              label="Niños edad"
                              outlined
                              v-model="producto.idrango_edad"
                           ></v-select>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select 
                              dense 
                              item-text="nombre" 
                              item-value="idmaterial" 
                              :items="resources.material" 
                              multiple 
                              label="Material" 
                              outlined 
                              v-model="producto.idmaterial"
                              hide-details
                           />
                           <button 
                              type="button" 
                              class="py-1"
                              @click="showSortableList.material = !showSortableList.material"
                              >
                              {{ showSortableList.material ? 'Ocultar': 'Mostrar' }} lista
                           </button>

                           <SortableList  
                              v-if="showSortableList.material"
                              item-text="nombre" 
                              item-value="idmaterial" 
                              :items="resources.material"
                              v-model="producto.idmaterial" 
                           />
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select 
                              dense 
                              item-text="nombre" 
                              item-value="idforma" 
                              :items="resources.forma" 
                              label="Forma" 
                              outlined 
                              multiple 
                              v-model="producto.idforma"
                              hide-details
                           />

                           <button 
                              type="button" 
                              class="py-1"
                              @click="showSortableList.forma = !showSortableList.forma"
                              >
                              {{ showSortableList.forma ? 'Ocultar': 'Mostrar' }} lista
                           </button>

                           <SortableList  
                              v-if="showSortableList.forma"
                              item-text="nombre" 
                              item-value="idforma" 
                              :items="resources.forma"
                              v-model="producto.idforma" 
                           />
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select dense item-text="nombre" item-value="idrostro" :items="resources.rostro" label="Rostro" outlined multiple v-model="producto.idrostro"></v-select>
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select
                              dense
                              item-text="nombre"
                              item-value="idproducto_color"
                              :items="resources.producto_color"
                              label="Montura Color"
                              outlined
                              multiple
                              v-model="producto.idproducto_color"
                              hide-details
                           />

                           <button 
                              type="button" 
                              class="py-1"
                              @click="showSortableList.color = !showSortableList.color"
                              >
                              {{ showSortableList.color ? 'Ocultar': 'Mostrar' }} lista
                           </button>

                           <SortableList  
                              v-if="showSortableList.color"
                              item-text="nombre" 
                              item-value="idproducto_color" 
                              :items="resources.producto_color"
                              v-model="producto.idproducto_color" 
                           />
                        </v-col>

                        <v-col cols="12" xl="6" lg="6" md="6" sm="12" v-show="!ocultarCampo">
                           <v-select dense item-text="nombre" item-value="idtag" :items="resources.tag" label="Tags" outlined multiple v-model="producto.idtag"></v-select>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
                           <v-text-field type="number" min="0" dense label="Inventario" v-model="producto.inventario"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12" v-show="!ocultarCampo">
                           <v-text-field type="number" dense label="Puente" v-model="producto.puente"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12" v-show="!ocultarCampo">
                           <v-text-field type="number" dense label="Luna" v-model="producto.luna"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12" v-show="!ocultarCampo">
                           <v-text-field type="number" dense label="Largo" v-model="producto.largo"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12" v-show="!ocultarCampo">
                           <v-text-field type="number" dense label="Ancho" v-model="producto.ancho"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12" v-show="!ocultarCampo">
                           <v-text-field type="number" dense label="Alto" v-model="producto.alto"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="4" lg="4" md="4" sm="12" v-show="false">
                           <v-text-field type="text" dense label="Unidad de medida" v-model="producto.unidad_medida"></v-text-field>
                        </v-col>

                        <v-col cols="12" v-show="!ocultarCampo">
                           <v-text-field dense label="Altura luna" v-model="producto.altura_luna"></v-text-field>
                        </v-col>

                        <v-col cols="12" xl="12" lg="12" md="12" sm="12" v-show="!ocultarCampo">
                           <v-select dense item-text="nombre" item-value="relevancia" :items="relevanciaRango" label="Relevancia" outlined v-model="producto.relevancia"></v-select>
                        </v-col>

                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                           <p class="my-0">Clip On:</p>
                           <v-switch :true-value="1" :false-value="0" v-model="producto.clip_on" flat :label="producto.clip_on ? 'SI' : 'NO'"></v-switch>
                        </v-col>

                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                           <p class="my-0">Descuentos:</p>
                           <v-switch :true-value="1" :false-value="0" v-model="producto.en_descuento" flat :label="producto.en_descuento ? 'SI' : 'NO'"></v-switch>
                        </v-col>

                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                           <p class="my-0">Luz Azul Gratis:</p>
                           <v-switch :true-value="1" :false-value="0" v-model="producto.luz_azul_gratis" flat :label="producto.luz_azul_gratis ? 'SI' : 'NO'"></v-switch>
                        </v-col>

                        <v-col cols="12" xl="3" lg="3" md="3" sm="12">
                           <p class="my-0">Envio Gratis:</p>
                           <v-switch :true-value="1" :false-value="0" v-model="producto.envio_gratis" flat :label="producto.envio_gratis ? 'SI' : 'NO'"></v-switch>
                        </v-col>

                        <v-col cols="12">
                           <v-text-field dense label="Link Video" v-model="producto.linkVideo"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <v-textarea dense label="Descripción" v-model="producto.descripcion"></v-textarea>
                        </v-col>

                        <v-col cols="12" v-if="producto.idclase.includes(3)">
                           <v-textarea dense label="Descripción para lentes de sol" v-model="producto.descripcion_tipo_sol"></v-textarea>
                        </v-col>

                        <v-col cols="12">
                           <p class="my-0">Contenido</p>
                           <ckeditor
                              :editor-url="apiUrl('js/ckeditor/ckeditor.js')"
                              v-model="producto.contenido"
                              :config="{
                              filebrowserBrowseUrl: '/plugin_externos/ckfinder/ckfinder.html',
                              allowedContent: true,
                              }"
                           ></ckeditor>
                        </v-col>

                        <v-col cols="12">
                           <p>Imagenes:</p>
                           <p class="text-center">
                              <v-btn
                                 :loading="loaderImagen"
                                 color="primary"
                                 @click="
                                    $refs.iptImagen.value = '';
                                    $refs.iptImagen.click();
                                 "
                              >
                                 <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGENES
                              </v-btn>
                           </p>
                           <input style="display: none" multiple type="file" ref="iptImagen" @change.prevent="cargarImagenes" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row v-show="imagen.length > 0">
                                 <v-col cols="12">
                                    <v-simple-table dense="" fixed-header="">
                                       <template v-slot:default>
                                          <thead class="text-no-wrap">
                                             <tr>
                                                <th>MOVER</th>
                                                <th>ORDEN</th>
                                                <th>IMAGEN</th>
                                                <th>ORIENTACIÓN</th>
                                                <th>ELIMINAR</th>
                                             </tr>
                                          </thead>
                                          <draggable @update="cambiarOrdenImagen" v-model="imagen" tag="tbody" handle=".cursor-move">
                                             <tr v-for="(img, index) in imagen" :key="index">
                                                <td class="cursor-move">
                                                   <v-icon>mdi-drag-variant</v-icon>
                                                </td>
                                                <td v-text="img.orden"></td>
                                                <td>
                                                   <v-img height="120" width="100" :src="assetAWS('monturas/' + img.nombre)" contain></v-img>
                                                </td>
                                                <td v-if="index == 0">FRONTAL</td>
                                                <td v-else-if="index == 1">PERFIL</td>
                                                <td v-else-if="index == 2">LATERAL</td>
                                                <td v-else-if="index == 3">CENITAL</td>
                                                <td v-else>OTROS</td>
                                                <td>
                                                   <v-btn icon color="red" small @click.prevent="eliminarImagen(img.idproducto_imagen)">
                                                      <v-icon>mdi-trash-can</v-icon>
                                                   </v-btn>
                                                </td>
                                             </tr>
                                          </draggable>
                                       </template>
                                    </v-simple-table>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="12" v-show="!ocultarCampo">
                           <p>Colores:</p>
                           <p class="text-center">
                              <v-btn
                                 :loading="loaderImagenColor"
                                 color="primary"
                                 @click="
                                    $refs.iptImagenesColor.value = '';
                                    $refs.iptImagenesColor.click();
                                 "
                              >
                                 <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR COLORES
                              </v-btn>
                           </p>
                           <input style="display: none" multiple type="file" ref="iptImagenesColor" @change.prevent="cargarImagenColor" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row v-show="imagen_color.length > 0">
                                 <v-col cols="12">
                                    <v-simple-table dense="" fixed-header="">
                                       <template v-slot:default>
                                          <thead class="text-no-wrap">
                                             <tr>
                                                <th>MOVER</th>
                                                <th>ORDEN</th>
                                                <th>IMAGEN</th>
                                                <th>ELIMINAR</th>
                                             </tr>
                                          </thead>
                                          <draggable @update="cambiarOrdenImagenesColor" v-model="imagen_color" tag="tbody" handle=".cursor-move">
                                             <tr v-for="(img, index) in imagen_color" :key="index">
                                                <td class="cursor-move">
                                                   <v-icon>mdi-drag-variant</v-icon>
                                                </td>
                                                <td v-text="img.orden"></td>
                                                <td>
                                                   <v-img height="120" width="100" :src="assetAWS('monturas/' + img.nombre)" contain></v-img>
                                                </td>
                                                <td>
                                                   <v-btn icon color="red" small @click.prevent="eliminarImagenesColor(img.idproducto_imagen_color)">
                                                      <v-icon>mdi-trash-can</v-icon>
                                                   </v-btn>
                                                </td>
                                             </tr>
                                          </draggable>
                                       </template>
                                    </v-simple-table>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="4" v-show="!ocultarCampo">
                           <p>Imagen Probador:</p>
                           <input style="display: none" type="file" ref="iptProbadorVirutal" @change.prevent="cargarProbadorVirtual" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row>
                                 <v-col cols="12" class="text-center" v-show="urlProbadorVirutal === ''">
                                    <v-btn
                                       :loading="loaderProbadorVirtual"
                                       color="primary"
                                       @click="
                                          $refs.iptProbadorVirutal.value = '';
                                          $refs.iptProbadorVirutal.click();
                                       "
                                    >
                                       <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR PROBADOR VIRTUAL
                                    </v-btn>
                                 </v-col>
                                 <v-col cols="12" class="text-center" v-show="urlProbadorVirutal !== ''">
                                    <v-btn icon color="red" small @click.prevent="eliminarProbadorVirtual">
                                       <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                    <v-img :src="urlProbadorVirutal" height="200" contain> </v-img>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="4" v-show="!ocultarCampo">
                           <p>Imagen Probador SOL:</p>
                           <input style="display: none" type="file" ref="iptProbadorVirutalSol" @change.prevent="cargarProbadorVirtualSol" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row>
                                 <v-col cols="12" class="text-center" v-show="urlProbadorVirutalSol === ''">
                                    <v-btn
                                       :loading="loaderProbadorVirtualSol"
                                       color="primary"
                                       @click="
                                          $refs.iptProbadorVirutalSol.value = '';
                                          $refs.iptProbadorVirutalSol.click();
                                       "
                                    >
                                       <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR PROBADOR VIRTUAL SOL
                                    </v-btn>
                                 </v-col>
                                 <v-col cols="12" class="text-center" v-show="urlProbadorVirutalSol !== ''">
                                    <v-btn icon color="red" small @click.prevent="eliminarProbadorVirtualSol">
                                       <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                    <v-img :src="urlProbadorVirutalSol" height="200" contain> </v-img>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="4" v-show="!ocultarCampo">
                           <p>Imagen cuadrada para ads:</p>
                           <input style="display: none" type="file" ref="imgCuadrado" @change.prevent="cargarImgCuadrado" accept="image/*" />
                           <div class="custom-my-dropzone">
                              <v-row>
                                 <v-col cols="12" class="text-center" v-show="urlImgCuadrado === ''">
                                    <v-btn
                                       :loading="loaderImgCuadrado"
                                       color="primary"
                                       @click="
                                          $refs.imgCuadrado.value = '';
                                          $refs.imgCuadrado.click();
                                       "
                                    >
                                       <v-icon left>mdi-cloud-upload-outline</v-icon> CARGAR IMAGEN CUADRADA
                                    </v-btn>
                                 </v-col>
                                 <v-col cols="12" class="text-center" v-show="urlImgCuadrado !== ''">
                                    <v-btn icon color="red" small @click.prevent="eliminarImgCuadrado">
                                       <v-icon>mdi-trash-can</v-icon>
                                    </v-btn>
                                    <v-img :src="urlImgCuadrado" height="200" contain> </v-img>
                                 </v-col>
                              </v-row>
                           </div>
                        </v-col>

                        <v-col cols="12">
                           <v-select dense item-text="texto" item-value="valor" :items="resources.estado" label="Estado(*)" outlined v-model="producto.estado"></v-select>
                        </v-col>

                        <v-col cols="12" class="text-center">
                           <v-btn class="mr-2" type="submit" :loading="processForm" color="success" large=""> <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR </v-btn>
                           <v-btn :to="{ name: 'productosPanel' }" type="button" :loading="processForm" color="error" large=""> <v-icon left="" large="">mdi-close</v-icon> CANCELAR </v-btn>
                        </v-col>
                     </v-row>
                  </v-form>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   import draggable from "vuedraggable";
   import SortableList from "@/components/SortableList.vue";

   export default {
      components: {
         draggable,
         SortableList,
      },
      data() {
         return {
            resources: {
               tipo_producto: [],
               clase: [],
               categoria: [],
               marco: [],
               rango: [],
               material: [],
               forma: [],
               genero: [],
               rostro: [],
               producto_color: [],
               tag: [],
               estado: [
                  {
                     texto: "Habilitado",
                     valor: 1,
                  },
                  {
                     texto: "Inhabilitado",
                     valor: 0,
                  },
               ],
            },
            producto: {
               _method: "PUT",
               idproducto: "",
               idclase: [],
               idtipo_producto: "",
               idcategoria: "",
               idgenero: [],
               idmarco: "",
               idforma: [],
               idrango_edad: "",
               idmaterial: [],
               idproducto_color: [],
               idtag: [],
               nombre: "",
               codigo: "",
               descripcion: "",
               descripcion_tipo_sol: "",
               inventario: "",
               precio: "",
               precio_lista: "",
               puente: "",
               luna: "",
               ancho: "",
               largo: "",
               alto: "",
               altura_luna: "",
               clip_on: 0,
               en_descuento: 0,
               luz_azul_gratis: 0,
               envio_gratis: 0,
               linkVideo: "",
               imagen_probador: "",
               imagen_probador_sol: "",
               imagen_cuadrado: "",
               estado: 1,
               relevancia: 20,
               idrostro: [],
               contenido: null,
               unidad_medida: '',
            },
            urlProbadorVirutal: "",
            urlProbadorVirutalSol: "",
            urlImgCuadrado: "",
            imagen: [],
            imagen_color: [],
            ocultarCampo: false,
            mostrarContenido: false,
            processForm: false,
            listErrors: [],
            alertError: false,
            loaderImagen: false,
            loaderImagenColor: false,
            loaderProbadorVirtual: false,
            loaderProbadorVirtualSol: false,
            loaderImgCuadrado: false,
            showSortableList:{
               material: false,
               forma: false,
               color: false,
            }
         };
      },
      computed: {
         relevanciaRango: () => {
            let rango = [];
            for (let val = 1; val <= 20; val++) {
               rango.push(val);
            }
            return rango;
         },
      },
      methods: {
         evtTipoProducto() {
            if (this.producto.idtipo_producto == 2) {
               this.ocultarCampo = true;
            } else {
               this.ocultarCampo = false;
            }
         },

         cargarProbadorVirtual(event) {
            this.loaderProbadorVirtual = true;

            if (this.urlProbadorVirutal !== "") {
               this.$toasted.error("Ya hay un archivo cargado");
               this.loaderProbadorVirtual = false;
               return;
            }

            if (event.target.files.length > 0) {
               let imagen = event.target.files[0];
               this.urlProbadorVirutal = URL.createObjectURL(imagen);
               this.producto.imagen_probador = imagen;
            }

            this.loaderProbadorVirtual = false;
         },

         eliminarProbadorVirtual() {
            this.urlProbadorVirutal = "";
            this.producto.imagen_probador = "";
         },

         cargarProbadorVirtualSol(event) {
            this.loaderProbadorVirtualSol = true;

            if (this.urlProbadorVirutalSol !== "") {
               this.$toasted.error("Ya hay un archivo cargado");
               this.loaderProbadorVirtualSol = false;
               return;
            }

            if (event.target.files.length > 0) {
               let imagen = event.target.files[0];
               this.urlProbadorVirutalSol = URL.createObjectURL(imagen);
               this.producto.imagen_probador_sol = imagen;
            }

            this.loaderProbadorVirtualSol = false;
         },

         eliminarProbadorVirtualSol() {
            this.urlProbadorVirutalSol = "";
            this.producto.imagen_probador_sol = "";
         },

         cargarImgCuadrado(event) {
            this.loaderImgCuadrado = true;

            if (this.urlImgCuadrado !== "") {
               this.$toasted.error("Ya hay un archivo cargado");
               this.loaderImgCuadrado = false;
               return;
            }

            if (event.target.files.length > 0) {
               let imagen = event.target.files[0];
               this.urlImgCuadrado = URL.createObjectURL(imagen);
               this.producto.imagen_cuadrado = imagen;
            }

            this.loaderImgCuadrado = false;
         },

         eliminarImgCuadrado() {
            this.urlImgCuadrado = "";
            this.producto.imagen_cuadrado = "";
         },

         cambiarOrdenImagen() {
            this.loaderImagen = true;

            this.axios({
               method: "POST",
               url: "api/panel/productos/ordenarArchivos",
               data: {
                  idproducto: this.producto.idproducto,
                  tipoArchivo: "imagen",
                  imagen: JSON.stringify(this.imagen),
               },
            })
               .then((response) => {
                  if (response.status === 200) {
                     this.$toasted.success(response.data.mensaje, {
                        icon: "mdi-check-bold",
                     });

                     this.imagen = response.data.imagenes;
                  }
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.loaderImagen = false;
               });
         },

         cargarImagenes(event) {
            this.loaderImagen = true;

            if (event.target.files && event.target.files.length > 0) {
               let imagen = event.target.files;
               const form = this.serialize();

               Object.values(imagen).forEach((value) => {
                  form.append("imagen[]", value);
               });
               form.append("idproducto", this.producto.idproducto);
               form.append("tipoArchivo", "imagen");

               this.axios({
                  method: "POST",
                  url: "api/panel/productos/agregarArchivos",
                  data: form,
               })
                  .then((response) => {
                     if (response.status === 200) {
                        this.$toasted.success(response.data.mensaje, {
                           icon: "mdi-check-bold",
                        });

                        this.imagen = response.data.imagenes;
                     }
                  })
                  .catch((error) => {
                     console.log(error);
                  })
                  .then(() => {
                     this.loaderImagen = false;
                  });
            }
         },

         eliminarImagen(idproducto_imagen) {
            this.loaderImagen = true;
            this.axios({
               method: "POST",
               url: "api/panel/productos/eliminarAchivo",
               data: {
                  idproducto: this.producto.idproducto,
                  idproducto_imagen: idproducto_imagen,
                  tipoArchivo: "imagen",
               },
            })
               .then((response) => {
                  if (response.status === 200) {
                     this.$toasted.success(response.data.mensaje, {
                        icon: "mdi-check-bold",
                     });

                     this.imagen = response.data.imagenes;
                  }
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.loaderImagen = false;
               });
         },

         cambiarOrdenImagenesColor() {
            this.loaderImagenColor = true;

            this.axios({
               method: "POST",
               url: "api/panel/productos/ordenarArchivos",
               data: {
                  idproducto: this.producto.idproducto,
                  tipoArchivo: "imagen_color",
                  imagen_color: JSON.stringify(this.imagen_color),
               },
            })
               .then((response) => {
                  if (response.status === 200) {
                     this.$toasted.success(response.data.mensaje, {
                        icon: "mdi-check-bold",
                     });

                     this.imagen_color = response.data.imagenes_color;
                  }
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.loaderImagenColor = false;
               });
         },

         cargarImagenColor(event) {
            this.loaderImagenColor = true;

            if (event.target.files && event.target.files.length > 0) {
               let imagen = event.target.files;
               const form = this.serialize();

               Object.values(imagen).forEach((value) => {
                  form.append("imagen_color[]", value);
               });
               form.append("idproducto", this.producto.idproducto);
               form.append("tipoArchivo", "imagen_color");

               this.axios({
                  method: "POST",
                  url: "api/panel/productos/agregarArchivos",
                  data: form,
               })
                  .then((response) => {
                     if (response.status === 200) {
                        this.$toasted.success(response.data.mensaje, {
                           icon: "mdi-check-bold",
                        });

                        this.imagen_color = response.data.imagenes_color;
                     }
                  })
                  .catch((error) => {
                     console.log(error);
                  })
                  .then(() => {
                     this.loaderImagenColor = false;
                  });
            }
         },

         eliminarImagenesColor(idproducto_imagen_color) {
            this.loaderImagenColor = true;
            this.axios({
               method: "POST",
               url: "api/panel/productos/eliminarAchivo",
               data: {
                  idproducto: this.producto.idproducto,
                  idproducto_imagen_color: idproducto_imagen_color,
                  tipoArchivo: "imagen_color",
               },
            })
               .then((response) => {
                  if (response.status === 200) {
                     this.$toasted.success(response.data.mensaje, {
                        icon: "mdi-check-bold",
                     });

                     this.imagen_color = response.data.imagenes_color;
                  }
               })
               .catch((error) => {
                  console.log(error);
               })
               .then(() => {
                  this.loaderImagenColor = false;
               });
         },

         getRangos() {
            this.axios({
               method: "POST",
               url: "api/panel/productos/getRangos",
               data: {
                  idgenero: this.producto.idgenero,
               },
            })
               .then((response) => {
                  if (response.status === 200) {
                     this.resources.rango = response.data.data;

                     if (this.resources.rango.length == 0) {
                        this.producto.idrango_edad = "";
                     }
                  }
               })
               .catch((error) => {
                  console.log(error);
               });
         },

         getDatos() {
            this.mostrarContenido = false;

            this.axios({
               method: "GET",
               url: "api/panel/productos/create",
            })
            .then((response) => {
               const status = response.status;
               const data = response.data;

               if (status === 200) {
                  this.resources.tipo_producto = data.tipo_producto;
                  this.resources.clase = data.clases;
                  this.resources.categoria = data.categorias;
                  this.resources.marco = data.marcos;
                  this.resources.material = data.materiales;
                  this.resources.forma = data.formas;
                  this.resources.genero = data.generos;
                  this.resources.rostro = data.rostros;
                  this.resources.producto_color = data.producto_color;
                  this.resources.tag = data.tags;
               }
            })
            .catch((error) => {
               console.log(error);
            })


            this.axios({
               method: "GET",
               url: "api/panel/productos/" + this.$route.params.idproducto + "/edit",
            })
            .then((response) => {
               const status = response.status;
               const data = response.data;
               const producto = data.producto;

               if (status === 200) {

                  this.producto.idproducto = producto.idproducto;
                  this.producto.idtipo_producto = producto.idtipo_producto;
                  this.producto.nombre = producto.nombre;
                  this.producto.codigo = producto.codigo;
                  this.producto.precio_lista = producto.precio_lista;
                  this.producto.precio = producto.precio;
                  this.producto.idclase = producto.clases_pivot;
                  this.producto.idcategoria = producto.idcategoria;
                  this.producto.idgenero = producto.generos_pivot;
                  this.producto.idmarco = producto.idmarco;
                  this.producto.idrango_edad = producto.idrango_edad;
                  this.producto.idmaterial = producto.materiales_pivot;
                  this.producto.idproducto_color = producto.producto_color_pivot;
                  this.producto.idtag = producto.tags_pivot;
                  // this.producto.idforma         = producto.idforma;
                  this.producto.idforma = producto.formas_pivot;
                  this.producto.inventario = producto.inventario;
                  this.producto.puente = producto.puente;
                  this.producto.luna = producto.luna;
                  this.producto.largo = producto.largo;
                  this.producto.ancho = producto.ancho;
                  this.producto.alto = producto.alto;
                  this.producto.altura_luna = producto.altura_luna;
   
                  this.producto.clip_on = producto.clip_on;
                  this.producto.en_descuento = producto.en_descuento;
                  this.producto.luz_azul_gratis = producto.luz_azul_gratis;
                  this.producto.envio_gratis = producto.envio_gratis;
                  
                  this.producto.linkVideo = producto.linkVideo;
                  this.producto.descripcion = producto.descripcion;
                  this.producto.descripcion_tipo_sol = producto.descripcion_tipo_sol;
                  this.producto.estado = producto.estado;
                  this.producto.relevancia = producto.relevancia ? producto.relevancia : 20;
                  this.producto.idrostro = producto.rostros_pivot;
                  this.producto.contenido = producto.contenido;
                  this.producto.unidad_medida = producto.unidad_medida;

                  if (producto.imagen_probador != null) {
                     this.urlProbadorVirutal = this.assetAWS("monturas/" + producto.imagen_probador);
                  }

                  if (producto.imagen_probador_sol != null) {
                     this.urlProbadorVirutalSol = this.assetAWS("monturas/" + producto.imagen_probador_sol);
                  }

                  if (producto.imagen_cuadrado != null) {
                     this.urlImgCuadrado = this.assetAWS("monturas/" + producto.imagen_cuadrado);
                  }

                  this.imagen = producto.imagenes;
                  this.imagen_color = producto.imagenes_color;

                  this.getRangos();

                  this.evtTipoProducto();
               }

               this.mostrarContenido = true;
            })
            .catch((error) => {
               this.$toasted.error("Ocurrio un error al buscar este registro", {
                  icon: "mdi-close",
               });

               this.$router.push({
                  name: "productosPanel",
               });
            })
            .finally(() => {});
         },

         modificar() {
            this.processForm = true;
            this.alertError = false;

            this.axios({
               method: "POST",
               url: "api/panel/productos/" + this.producto.idproducto,
               data: this.serialize(this.producto),
            })
               .then((response) => {
                  let status = response.status;
                  if (status === 200) {
                     this.$toasted.success(response.data.data, {
                        icon: "mdi-check-bold",
                     });
                     this.$router.push({
                        name: "productosPanel",
                     });
                  }
               })
               .catch((error) => {
                  let status = error.response.status;
                  if (status === 422) {
                     let arrErrors = error.response.data.errors;
                     this.listErrors = Object.values(arrErrors);
                     this.alertError = true;
                     this.$vuetify.goTo(this.$refs.mensajeError);
                  } else {
                     this.$toasted.error("Ocurrio un error al modificar este registro", {
                        icon: "mdi-close",
                     });
                  }
               })
               .finally(() => {
                  this.processForm = false;
               });
         },
      },
      created() {
         if (!this.validarPermiso("productos.editar")) {
            this.$toasted.error("Usted no tiene permisos para esta sección", {
               icon: "mdi-close",
            });

            this.$router.push({
               name: "inicioPanel",
            });
         }
         window.scrollTo(0, 0);

         this.getDatos();
      },
   };
</script>

<style scoped>
   .cursor-move {
      cursor: move;
   }
</style>
